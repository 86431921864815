<template lang="pug">
.absolute.shadow.z-30.bottom-5.top-5.left-5.bg-white.grid.grid-cols-2.pr-8.pl-10.pt-5.rounded-xl.wrapper(
  style='grid-template-rows: auto auto 1fr auto; width: 800px'
)
  h1.col-start-1.col-end-2.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("history.title") }}
  .flex.justify-end.col-start-2.items-center
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click.stop='$emit("close")'
    )
  .grid.col-start-1.col-end-3.pb-3.mt-6.border-b.border-dividerColor.flex.items-center
    .col-start-1.col-end-2.pb-3.mt-6
      skif-search(
        style='width: 360px',
        v-model='filterString',
        :placeholder='$t("search")'
        @searching="searching"
      )
    .col-start-3.history-reportsDatepicker
      el-date-picker.mt-3.ml-2(
        v-model='date',
        :clearable='false',
        @paste.native='onPaste',
        :picker-options='pickerOptions',
        popper-class='history-reportsDatepicker',
        type='datetimerange',
        range-separator='-',
        :format='dateTimeFormat',
        value-format='yyyy-MM-dd HH:mm:ss',
        :start-placeholder='$t("reports.not-select")',
        :end-placeholder='$t("reports.not-select")',
        @change='changeDate'
      )
  .col-start-1.col-end-3.mt-6.overflow-y-auto.mb-2.pb-8
    table.table.w-full.table-fixed.text-left
      thead
        tr.table-row(style='height: 50px')
          th.text-center.history.table-cell.bg-white.top-0.sticky.color(
            style='width: 170px'
          ) {{ $t("history.date") }}
            svg.ml-2.transition-transform.ease-in-out.duration-500.cursor-pointer(
              @click='sortChange(sortProperties.created)',
              width='24',
              height='24',
              fill='none',
              xmlns='http://www.w3.org/2000/svg',
              :style='sortProp === sortProperties.created && sortOrder === sortOrderValues.desc && { transform: "scale(1,-1)" }'
            )
              path.transition-colors.ease-in-out.duration-200(
                d='M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z',
                :fill='sortProp === sortProperties.created ? "#3C5E8F" : "#91B4E7"'
              )
              path.transition-colors.ease-in-out.duration-200(
                d='M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z',
                :fill='sortProp === sortProperties.created ? "#3C5E8F" : "#91B4E7"'
              )

          th.text-center.history.table-cell.bg-white.top-0.sticky.color(
            style='width: 250px'
          ) {{ $t("history.author") }}
            //- svg.ml-2.transition-transform.ease-in-out.duration-500(
            //-   @click='sortChange(sortProperties.name)',
            //-   width='24',
            //-   height='24',
            //-   fill='none',
            //-   xmlns='http://www.w3.org/2000/svg',
            //-   :style='sortProp === sortProperties.name && sortOrder === sortOrderValues.desc && { transform: "scale(1,-1)" }'
            //- )
            //-   path.transition-colors.ease-in-out.duration-200(
            //-     d='M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z',
            //-     :fill='sortProp === sortProperties.name ? "#3C5E8F" : "#91B4E7"'
            //-   )
            //-   path.transition-colors.ease-in-out.duration-200(
            //-     d='M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z',
            //-     :fill='sortProp === sortProperties.name ? "#3C5E8F" : "#91B4E7"'
            //-   )
          th.text-center.history.table-cell.bg-white.top-0.sticky.color(
            style='width: 130px'
          ) {{ $t("history.operation") }}
            svg.ml-2.transition-transform.ease-in-out.duration-500.cursor-pointer(
              @click='sortChange(sortProperties.operation)',
              width='24',
              height='24',
              fill='none',
              xmlns='http://www.w3.org/2000/svg',
              :style='sortProp === sortProperties.operation && sortOrder === sortOrderValues.desc && { transform: "scale(1,-1)" }'
            )
              path.transition-colors.ease-in-out.duration-200(
                d='M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z',
                :fill='sortProp === sortProperties.operation ? "#3C5E8F" : "#91B4E7"'
              )
              path.transition-colors.ease-in-out.duration-200(
                d='M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z',
                :fill='sortProp === sortProperties.operation ? "#3C5E8F" : "#91B4E7"'
              )
          th.text-center.history.table-cell.bg-white.top-0.sticky.color {{ $t("history.event") }}

      tbody
        tr.border-b.border-dividerColor(
          style='height: 80px',
          v-for='element in historyTable.list'
        )
          th.color.history {{ element.created.slice(5, -3) }}
          th.color.history
            .flex.flex-col
              span.history {{ getName(element.user) }}
              span.history.email.mt-2 {{ getEmail(element.user) }}
          th.color.history {{ element.operation_value }}
          th.color.history {{ element.msg }}
  .mt-3.w-full.justify-self-center.absolute.bottom-0.bg-white.flex.justify-center.pt-2
    el-pagination(
      @prev-click='prevPage',
      @next-click='nextPage',
      @current-change='handleCurrentChange',
      layout='prev, pager, next',
      :pageSize='pageSize',
      :total='historyTable.max_rows'
    )
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { sortOrder as sortOrderValues, sortProperties } from './constants.js'

export default {
  props: {
    historyTable: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      formData: '',
      pageNumber: 1,
      pageSize: 100,
      filterString: '',
      date: ['', ''],
      sortProp: '',
      sortOrder: '',
      pickerOptions: {
        firstDayOfWeek: 1
      },
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      moduleType: '',
      sortProperties,
      sortOrderValues
    }
  },

  computed: {
    ...mapState('login', {
      me: (state) => state.me
    })
  },

  methods: {
    changeDate(dates) {
      this.date = dates
      this.getTableData()
    },

    searching(val) {
      this.filterString = val
      this.getTableData()
    },

    handleCurrentChange(val) {
      this.pageNumber = val
      this.getTableData()
    },
    prevPage() {
      this.pageNumber -= 1
    },

    nextPage() {
      this.pageNumber += 1
    },

    getTableData() {
      let sortField = this.sortProp

      if (this.sortProp === '') sortField = sortProperties.created
      if (this.sortProp === sortProperties.name) sortField = 'user.name'

      const firstRow = (this.pageNumber - 1) * this.pageSize
      const formData = {
        objects: this.historyTable.objects,
        dates: this.date,
        sortField,
        sortDesc: this.sortOrder === sortOrderValues.desc,
        value: this.filterString
      }

      this.$emit('show-history', { formData, firstRow })
    },

    sortChange(prop) {
      if (this.sortProp === prop) {
        if (this.sortOrder === sortOrderValues.asc) {
          this.sortOrder = sortOrderValues.desc
          this.getTableData()
        } else if (this.sortOrder === sortOrderValues.desc) {
          this.sortOrder = ''
          this.sortProp = ''
        } else {
          this.sortOrder = sortOrderValues.asc
          this.getTableData()
        }
      } else {
        this.sortProp = prop
        this.sortOrder = sortOrderValues.asc
        this.getTableData()
      }
    },

    onPaste(event) {
      const dates = event.clipboardData.getData('text').split('\n')
      if (
        dates.length === 2 &&
        moment(dates[0], 'yyyy-MM-dd HH:mm:ss').isValid() &&
        moment(dates[1], 'yyyy-MM-dd HH:mm:ss').isValid()
      ) {
        this.ruleForm.datePicker = dates
      }
    },

    getName(property) {
      if (!property) return '-'
      return property.name
    },

    getEmail(property) {
      if (!property) return '-'
      return property.email
    }
  },

  created() {
    this.formData = this.$parent.formDataHistory

    this.date[0] = moment().format(this.formData.from, 'YYYY-MM-DD HH:mm:ss')
    this.date[1] = moment().format(this.formData.to, 'YYYY-MM-DD HH:mm:ss')
    this.dateFormat = this.me.active_company.dateformat.key
    this.timeFormat = this.me.active_company.timeformat.key
    this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
    const formattedTime = localStorage.getItem('formattedTime')
    const endDate = this.formData.to
    const momentDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss')
    // COMMENTED FOR A WHILE and SET ONLY 00 always. In the future it will be replaced by the backend data

    // if (formattedTime && formattedTime === '23:59:59') {
    //   momentDate.set({ hour: 23, minute: 59, second: 59 })
    //   this.formData.to = momentDate.format('YYYY-MM-DD HH:mm:ss')
    //   this.date[1] = momentDate.format('YYYY-MM-DD HH:mm:ss')
    // } else {
    //   momentDate.set({ hour: 0, minute: 0, second: 0 })
    //   this.formData.to = momentDate.format('YYYY-MM-DD HH:mm:ss')
    //   this.date[1] = momentDate.format('YYYY-MM-DD HH:mm:ss')
    // }

    momentDate.set({ hour: 0, minute: 0, second: 0 })
    this.formData.to = momentDate.format('YYYY-MM-DD HH:mm:ss')
    this.date[1] = momentDate.format('YYYY-MM-DD HH:mm:ss')

    // COMMENTED FOR A WHILE and SET ONLY 00 always. In the future it will be replaced by the backend data
  },

  mounted() {}
}
</script>
<style>
.history-reportsDatepicker .el-date-editor .el-range-separator {
  line-height: 27px;
}
</style>
<style scoped>
.wrapper {
  z-index: 999;
}
.history {
  color: #5477a9;
  font-size: 14px;
  font-weight: bold;
}

.email {
  color: #95baec;
  font-size: 14px;
}

.color {
  color: #5477a9;
}
</style>
